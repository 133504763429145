<template>
    <div>
        <div class="d-flex justify-content-center">
            <b-card class="text-center m-1">
                <div class="m-3">
                    <p>
                        <feather-icon
                            icon="CheckCircleIcon"
                            size="80"
                            class="text-success"
                        />
                    </p>
                    <h2>Payment Successful.</h2>
                    <p>Thank you for your purchase.</p>
                </div>
                <b-link :to="{ name: 'downloader' }"
                    ><b-button variant="primary" class="mb-1"
                        >Go to Downloader</b-button
                    ></b-link
                >
            </b-card>
        </div>
    </div>
</template>

<script>
import { BCard, BButton, BLink } from "bootstrap-vue";
export default {
    components: {
        BCard,
        BButton,
        BLink,
    },
};
</script>

<style></style>
